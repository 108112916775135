/* eslint-disable no-param-reassign */
import Vue from 'vue';

Vue.filter('priceFilter', (price) => {
  price = (Math.floor(price * 100) / 100).toFixed(2);
  price = price.toString().replace(/\./g, ',');
  price = price.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return price;
});

Vue.filter('numberFilter', (number) => {
  number = number.toString().replace(/\./g, ',');
  number = number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return number;
});

Vue.filter('numberSeparator', (number) => {
  if (number) {
    number = number.toString().replace(/\./g, ',');
    number = number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return number;
});
