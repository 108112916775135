module.exports = {
  en: {
    message: {
      welcome: 'Welcome',
      loan_title: 'Simulate your loan',
      loan_type: 'Loan type',
      pick_amount: 'Pick an amount',
      loan_amountinput: 'Amount choosen',
      pick_duration: 'Choose duration',
      your_monthly_repayment: 'Your monthly repayment',
      credit_goal: 'Credit goal',
      amount_to_borrow: 'Amount to borrow',
      picked_duration: 'Choosen duration',
      taeg: 'Taux Annuel Effectif Global',
      taux_short: 'Taux',
      taeg_short: 'Taux / TAEG*',
      taux_debiteur: 'Taux débiteur',
      months: 'months',
      years: 'years',
      others_loans: 'Other loans',
      tdaf: 'Taux débiteur actuariel fixe',
      interests_total: 'Total of interests',
      total_amount_to_pay: 'Total amount to pay',
      montly_repayment: 'Monthly repayments',
      CTA_online_request: 'Get your loan',
      start_loan: 'Get your loan now',
      simulation_result: "Simulation's results",
      taux_debiteur_modal: "Le taux débiteur est le taux réel de l'emprunt hypothécaire. Il est repris sur le tableau d'amortissement. Il ne comprend pas les frais annexes qui détermineront le TAEG final.",
      goBack: 'Go back',
      next: 'Next',
      send: 'Send',
      formTitle: 'Form title',
      userInformations: 'User informations',
      locationInformations: 'Location informations',
      resultsTab: 'Results',
      firstName: 'First name',
      lastName: 'Last name',
      name: 'Name',
      tel: 'Tel/GSM',
      email: 'Email',
      streetName: 'Street name',
      streetNumber: 'Street number',
      city: 'City',
      zipCode: 'Zip code',
      captcha: 'Form validation',
      country: 'Country',
      yes: 'Yes',
      no: 'No',
      gdpr: 'By clicking the button below, you agree to the Terms of Service and Privacy Policy.',
      gdprMissing: 'Please agree',
    },
  },
  fr: {
    message: {
      welcome: 'Bienvenue',
      loan_title: 'Simulez votre crédit',
      loan_type: 'But du prêt',
      pick_amount: 'Montant',
      pick_credit_suppl: 'Personnalisez votre crédit',
      loan_amountinput: 'Capital Choisi',
      pick_duration: 'Durée',
      your_monthly_repayment: 'Votre mensualité',
      credit_goal: 'But du crédit',
      amount_to_borrow: 'Montant emprunté',
      picked_duration: 'Durée du crédit',
      taeg: 'Taux Annuel Effectif Global',
      taux_short: 'Taux',
      taeg_short: 'Taux / TAEG*',
      taux_debiteur: 'Taux débiteur',
      months: 'mois',
      years: 'ans',
      others_loans: 'Autres formules',
      tdaf: 'Taux débiteur actuariel fixe',
      interests_total: 'Total des intérêts',
      total_amount_to_pay: 'Montant total à payer',
      montly_repayment: 'Mensualité',
      CTA_online_request: 'Demande en ligne',
      start_loan: 'Démarrez votre demande de crédit',
      pick_credit_type: 'Type de crédit',
      simulation_result: 'Résultat de votre simulation',
      taux_debiteur_modal: "Le taux débiteur est le taux réel de l'emprunt hypothécaire. Il est repris sur le tableau d'amortissement. Il ne comprend pas les frais annexes qui détermineront le TAEG final.",
      taeg_modal_01: 'Le TAEG de ',
      taeg_modal_02: "exprime le total des coûts liés au crédit logement en pourcent. Toutefois, les primes d'assurance solde restant dû et d'incendie qui sont obligatoires pour obtenir le taux débiteur ne sont pas incluses dans ce TAEG car ces données sont inconnues à ce stade de la simulation.",
      taeg_modal_03: 'Vous trouverez ci-dessous le résultat pour',
      taeg_modal_04: 'et une durée de',
      taeg_modal_05: 'Frais',
      taeg_modal_06: 'Les frais de dossier',
      taeg_modal_07: 'Les frais de compte bancaire',
      taeg_modal_08: 'souscription chez Hello Bank',
      taeg_modal_09: 'Hypothèque',
      taeg_modal_10: "Frais d'acte de crédit",
      taeg_modal_11: "Cette simulation est donnée à titre d'exemple et ne représente en aucun cas une offre",
      taeg_modal_12: 'Contactez-nous pour recevoir une simulation de crédit précise',
      goBack: 'Retour',
      next: 'Suivant',
      send: 'Envoyer',
      formTitle: 'Réalisez une demande de crédit en ligne',
      resultsTab: 'Résultats',
      firstName: 'Prénom',
      lastName: 'Nom',
      name: 'Nom',
      tel: 'Tel/GSM',
      email: 'Email',
      streetName: 'Rue',
      streetNumber: 'n°',
      city: 'Ville',
      zipCode: 'Code postal',
      captcha: 'Validation du formulaire',
      country: 'Pays',
      yes: 'Oui',
      no: 'Non',
      gdpr: 'J\'ai pris connaissance de la politique concernant la charte de <a href="https://mycredit.be/mentions-legales/" target="_blank">vie privée</a>.',
      gdprMissing: 'Veuillez accepter les conditions',
      noneSelectedText: 'Faites un choix',
      borrowers: 'J\'emprunte',
      borrowersNumber: 'Nombre d\'emprunteurs',
      alone: 'Seul',
      twoBorrowers: 'À deux',
      personnalInformations: 'Informations personnelles',
      identity: 'Données d\'identité',
      yourData: 'Vos données',
      coBorrowerData: 'Les données du co-emprunteur',
      financialData: 'Données financières',
      currentCredits: 'Crédits en cours',
      howDoYouKnowUs: 'Comment nous connaissez-vous?',
      comments: 'Remarques',
      bornPlace: 'Lieu de naissance',
      bornDate: 'Date de naissance',
      nationality: 'Nationalité',
      address: 'Adresse',
      since: 'Depuis le',
      livingThereSince: 'Depuis quand à l\'adresse',
      livingInBelgiumSince: 'Je vis en Belgique depuis le',
      sex: 'Sexe',
      man: 'Homme',
      woman: 'Femme',
      nationalIdentificationNumber: 'N° de registre national',
      IDCardNumber: 'N° de carte d\'identité',
      endDateOfValidity: 'Date de fin de validité',
      civilStatus: 'État civil',
      single: 'Célibataire',
      coLiving: 'Co-habitant légal',
      marriedWithContract: 'Marié (avec contrat de marriage)',
      marriedWithoutContract: 'Marié (sans contrat de marriage)',
      separated: 'Séparé',
      widow: 'Veuf',
      numberOfChildren: 'Nombre d\'enfants à charge',
      amountOfBenefits: 'Montant des allocations',
      accountNumber: 'N° de compte',
      livingStatus: 'Statut d\'habitation',
      owner: 'Propriétaire',
      tenant: 'Locataire',
      cohabitant: 'Cohabitant',
      ownerSitutation: 'Si propriétaire',
      cohabitantSituation: 'Si cohabitant',
      withHypoCreditOnline: 'Avec crédit hypothécaire en ligne',
      housePaid: 'Maison payée',
      livingFree: 'Logé gratuitement',
      sharedRent: 'Loyer partagé',
      rentAmount: 'Loyer',
      oldAddress: 'Adresse précédente',
      profession: 'Profession',
      other: 'Autre',
      unemployed: 'Chomeur',
      diplomate: 'Diplomate',
      employed: 'Employé',
      student: 'Étudiant',
      selfEmployed: 'Indépendant',
      invalid: 'Invalide',
      mutual: 'Mutuelle',
      worker: 'Ouvrier',
      retieree: 'Pensionné',
      earlyRetieree: 'Prépensionné',
      withoutProfession: 'Sans profession',
      govJob: 'Travail d\'état',
      contractType: 'Type de contrat',
      cdi: 'Contrat à durée indéterminée',
      cdd: 'Contrat à durée déterminée',
      temporaryJob: 'Intérimaire',
      housekeeping: 'Ménagère',
      jobStartingDate: 'Date d\'engagement',
      bossName: 'Nom de l\'employeur',
      bossStreet: 'Rue et n° de l\'employeur',
      bossCity: 'Code postal, localité',
      bossCountry: 'Pays de l\'employeur',
      belgium: 'Belgique',
      france: 'France',
      luxemburg: 'Luxembourg',
      netIncome: 'Revenus net',
      mealVoucher: 'Chèques repas',
      selfEmployedIncome: 'Revenus d\'indépendant',
      actvityType: 'Nature de l\'activité',
      vatNumber: 'N° de TVA',
      otherIncome: 'Autres revenus',
      typeOfOtherIncome: 'Nature de ces revenus',
      moreIncome: 'Revenus supplémentaires',
      bankName: 'Nom de la banque',
      creditType: 'Type de crédit',
      creditAmount: 'Montant de départ',
      creditDate: 'Date de l’emprunt',
      creditLengthInMonths: 'Durée de l’emprunt en mois',
      mensuality: 'Mensualité',
      netBalance: 'Solde net',
      creditToBoughBack: 'Crédit à racheter',
      addACredit: 'Ajouter un crédit',
      decouvertSurCompte: 'Découvert sur compte',
      masterCard: 'Master Card',
      ouvertureDeCredit: 'Ouverture de crédit',
      pretATemperament: 'Prêt à tempérament',
      pretAHypo: 'Prêt hypothécaire',
      visa: 'Visa',
      ifNecessary: 'Si nécessaire',
    },
  },
};
