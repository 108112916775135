<template>
    <div class="s-container">
      <div id="contact-form">
        <div class="s-form-content">
          <!-- <h3 class="s-title-form">Vos demandes de crédits sont refusées?
            <br>
            <span>On peut vous aider!</span>
          </h3> -->
          <h4 class="">Résumé de votre demande</h4>
          <ul class="s-simulator-results-list">
            <li>Montant demandé {{ loanAmount | priceFilter }} €</li>
            <li>Durée {{ selectedRangeDuration }} 
              <span v-if="rangeDurationType === 'years'">&nbsp;ans</span>
              <span v-if="rangeDurationType === 'months'">&nbsp;mois</span>
            </li>
            <li>Mensualités {{ monthlyPayment | priceFilter }} €</li>
            <li>{{ $t("message.taeg_short") }} {{ selectedRangeRate | numberFilter }} % <br/> <span class="s-result-label-description">{{ $t("message.taeg") }}</span></li>
            <li>{{ $t("message.total_amount_to_pay") }} {{ totalToPay | priceFilter }} €</li>
          </ul>
          <form method="POST" @submit.prevent="checkForm();" novalidate="true">  
            <input type="hidden" name="loan_amount" v-model="loanAmount" />
            <input type="hidden" name="loan_duration" v-model="selectedRangeDuration" />
            <input type="hidden" name="loan_uniqueid" v-model="loanUniqueName" />
            <input type="hidden" name="monthly_payment" v-model="monthlyPayment" />
            <input type="hidden" name="loan_rate" v-model="selectedRangeRate" />            
            <div class="field">
              <label class="label">Nom / prénom</label>
              <input class="s-form-control" type="text" placeholder="Nom / prénom" name="borrower_name" v-model="borrower1.name">
              <p v-if="errors.borrower1.name" class="s-error-text">{{ errors.borrower1.name }}</p>
            </div>
            <div class="field">
              <label class="label">Adresse e-mail</label>
              <input class="s-form-control" type="text" placeholder="email" name="borrower_email" v-model="borrower1.email">
              <p v-if="errors.borrower1.email" class="s-error-text">{{ errors.borrower1.email }}</p>
            </div>
            <div class="field">
              <label class="label">Téléphone</label>
              <input class="s-form-control" type="text" placeholder="Tél." name="borrower_tel" v-model="borrower1.tel">
              <p v-if="errors.borrower1.tel" class="s-error-text">{{ errors.borrower1.tel }}</p>
            </div>
            <div class="field">
              <label class="label">Situation</label>
              <select class="s-form-control" name="borrower_situation" v-model="borrower1.situation">
                <option>Propriétaire en difficulté</option>
                <option>Factures impayées</option>
                <option>Fiché à la banque nationale</option>
                <option>Dette ONSS - impôts - lois sociales</option>
                <option>Besoin de liquidités</option>
                <option>Autre</option>
              </select>
              <p v-if="errors.borrower1.situation" class="s-error-text">{{ errors.borrower1.situation }}</p>
            </div> 
            <div class="field">
              <label class="label">Etes-vous propriétaire ou locataire?</label>
              <select class="s-form-control" name="borrower_propLoc" v-model="borrower1.propLoc">
                <option>Je suis propriétaire</option>
                <option>Je suis locataire avec une personne garante</option>
                <option>Je suis locataire sans personne garante</option>
              </select>
              <p v-if="errors.borrower1.propLoc" class="s-error-text">{{ errors.borrower1.propLoc }}</p>
            </div> 
            <div class="field charte_private_life_checkbox">
              <input type="checkbox" id="charte_checkbo" value="true" v-model="checkedChart">
              <label class="label has-text-white" for="charte_checkbo">J’ai pris connaissance de la <a target="_blank" href="http://www.telecredit.be/pdf/infoju.pdf">charte de vie privée</a> de Telecredit</label>
              <p v-if="errors.borrower1.checkedChart" class="s-error-text">{{ errors.borrower1.checkedChart }}</p>
            </div>         
            <button v-if="!sendedForm" type="submit" class="cta-btn">Commencer ma demande</button>
            <div class="columns">
              <p v-if="errors.length">
                <b>Corrigez les erreurs suivantes:</b>
                <ul>
                  <li v-for="error in errors">{{ error }}</li>
                </ul>
              </p>
              <span class="is-size-4 has-text-weight-bold has-text-success">{{ sendedMessage }}</span>
              <span class="has-text-danger">{{ errorSendedMessage }}</span>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
  name: "creditForm",
  props: [
    "loanAmount",
    "rangeDurationType",
    "selectedRangeDuration",
    "selectedRangeRate",
    "monthlyPayment",
    "loanUniqueName",
    "urlCreditFormAxios",
    "totalToPay"
    

  ],
  data() {
    return {
      selection: null,
      checkedChart: false,
      borrower1: {},
      sendedMessage: null,
      sendedForm: false,
      errorSendedMessage: null,
      errors: {
        borrower1: {}
      },
      creditInfos: {}
    };
  },
  methods: {
    checkForm() {
      this.errors = {
        borrower1: {}
      };
      this.checkErrorsBorrower1();
      if (Object.keys(this.errors.borrower1).length === 0) {
        this.sendForm();
      }
    },
    tracking() {
      if(process.env.NODE_ENV === "production") {
        dataLayer.push({'event':'form-demande-completed'});
      }

      if(process.env.NODE_ENV === "development") {
        // console.log('event:pageview virtualUrl:subscription-completed.html');
      }
    },
    sendForm() {
      this.borrower1.loanAmount            = this.loanAmount;
      this.borrower1.selectedRangeDuration = this.selectedRangeDuration;
      this.borrower1.loanUniqueName        = this.loanUniqueName;
      this.borrower1.monthlyPayment        = (Math.round(this.monthlyPayment * 100) / 100).toFixed(2);
      this.borrower1.selectedRangeRate     = this.selectedRangeRate;
      this.borrower1.totalToPay            = (Math.round(this.totalToPay * 100) / 100).toFixed(2);
      

      this.borrower1.rangeDurationType = 'mois';
      if(this.rangeDurationType == 'years') {
        this.borrower1.rangeDurationType = 'ans';
      }

      const formData = new FormData();

      formData.append("data", JSON.stringify(this.borrower1));

      let url = this.urlCreditFormAxios;

      axios
        .post(url, formData)
        .then(response => {
          if (response.status === 200) {
            this.sendedMessage = response.data;
            this.sendedForm = true;
            this.tracking();

            if(this.sendedMessage.redirection) {
              window.location.href=this.sendedMessage.redirection;
            }
          }
        })
        .catch(e => {
          this.errorSendedMessage = "Formulaire non envoyé.";
          // console.log(e);
        });
    },
    checkErrorsBorrower1() {
      if (!this.borrower1.name) this.errors.borrower1.name = "Nom requis.";
      if (!this.borrower1.email) this.errors.borrower1.email = "Email requis.";
      if (!this.validateEmail(this.borrower1.email)) this.errors.borrower1.email = "Format incorrect";
      if (!this.borrower1.tel) this.errors.borrower1.tel = "Tel requis.";
      if (!this.borrower1.situation) this.errors.borrower1.situation = "Situation requis.";
      if (!this.borrower1.propLoc) this.errors.borrower1.propLoc = "Champ requis.";
      if (!this.checkedChart)
        this.errors.borrower1.checkedChart =
          "Vous devez accepter la charte de vie privée";
    },
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  },
};
</script>

<style lang="scss">
</style>
