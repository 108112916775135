<template>
  <div id="app">
    <creditSimulator></creditSimulator>
  </div>
</template>

<script>
import creditSimulator from './components/creditSimulator.vue';
export default {
  name: 'app',
  components: {
    creditSimulator
  },
  data() {
    return {
      selection: null,
    };
  },
};
</script>

<style lang="scss">
  @import './assets/sass/main.scss';
</style>
