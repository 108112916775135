/* eslint-disable */
import urlsConfig from '../config/urlsConfig.json';
import db from '../config/firebaseInit';
import initialLoan from '../config/initialLoan';
import CreditForm from './creditForm.vue';

import {
  Money
} from 'v-money'

export default {
  name: 'creditSimulator',
  components: { 
    Money,
    creditForm: CreditForm 
  },
  data() {
    return {
      selectedLoanType: initialLoan,
      isInternetExplorer: false,
      loanAmount: 0,
      selectedRangeDuration: null,
      allCredits: null,
      subCredits: null,
      urlsConfig,
      durationIncrementsHypo: 5,
      durationIncrementsOthers: 6,
      queryString: window.location.search,
      source: null,
      firstLoad: true,
      formAsked: false,
      urlCreditFormAxios : '/simulator-mailing/',
      money: {
        decimal: ',',
        thousands: ' ',
        suffix: ' €',
        precision: 0
      },
      notChoosen: true
    };
  },
  mounted() {
    this.getDataFromFirebase();
  },
  computed: {
    amountMax() {
      return parseFloat(this.selectedLoanType.credit.amount_max);
    },
    selectedRangeDurationPlaceholder: {   
      get () {
        if(this.rangeDurationType === 'years') {
          return this.selectedRangeDuration / 12;
        } else {
          return this.selectedRangeDuration;
        }
      },
      set (newValue) {
        if(this.rangeDurationType === 'years') {
          this.selectedRangeDuration = newValue * 12;
        } else {
          this.selectedRangeDuration = newValue;
        }
      }
    },
    amountMin() {
      return parseFloat(this.selectedLoanType.credit.amount_min);
    },
    selectedLoanUniqueName() {
      return this.selectedLoanType.credit.loan_type_unique_name;
    },
    targetUrl() {
      return this.selectedLoanType.target_url;
    },
    selectedRangeRate() {
      return parseFloat(this.getSelectedRangeRate());
    },
    fraisActeCredit() {
      return this.calculActeCredit();
    },
    rangeDurationType() {
      return this.selectedLoanType.credit.range_duration_type;
    },
    amountIncrements() {
      return parseFloat(this.selectedLoanType.credit.incrementationAmount);
    },
    ranges() {
      return this.selectedLoanType.ranges;
    },
    monthlyPayment() {
      return this.getMonthlyRepayment();
    },
    taeg() {
      return this.selectedRangeRate;
    },
    totalToPay() {
      return this.getMonthlyRepayment() * this.selectedRangeDuration;
    },
    totalInterests() {
      return (this.getMonthlyRepayment() * this.selectedRangeDuration - this.loanAmount);
    },
    rangeMin() {
      return parseFloat(this.getRangeMin());
    },
    rangeMax() {
      return parseFloat(this.getRangeMax());
    },
    rangesSimulations() {
      return this.getSimulationForAllRanges();
    },
    footerDescription() {
      if (this.subCredits) {
        return this.selectedLoanType.credit.i18n[this.$root.$i18n.locale].description;
      }
      return this.selectedLoanType.i18n[this.$root.$i18n.locale].description;
    },
  },
  methods: {
    getDataFromFirebase() {
      db.collection('credits_config')
        .get()
        .then((querySnapshot) => {
          this.loading = false;
          querySnapshot.forEach((doc) => {
            this.allCredits = doc.data().all_credits;
            this.selectedLoanType.target_url = this.allCredits[0].target_url;
            this.selectedLoanType.i18n = this.allCredits[0].i18n;
            this.selectedLoanType.loan_unique_name = this.allCredits[0].loan_unique_name;
            this.selectedLoanType.credit = this.allCredits[0].sub_credits[0];
            if (this.allCredits[0].sub_credits.length > 1) {
              this.subCredits = this.allCredits[0].sub_credits;
            } else {
              this.subCredits = null;
            }
            this.initializeData();
          });
        });
    },
    initializeData() {
      if (this.firstLoad) {
        const location = String(window.location.href);
        this.findConfig(location);
        this.findSource(location);
        this.firstLoad = false;
      }


      if (this.loanAmount < parseFloat(this.selectedLoanType.credit.amount_min) || this.loanAmount >
        parseFloat(this.selectedLoanType.credit.amount_max)) {
        this.loanAmount = parseFloat(this.selectedLoanType.credit.amount_default);
      }
      this.selectedRangeDuration = parseInt(this.selectedLoanType.credit.range_duration_default);
    },
    findConfig(location) {
      let foundConfig = false;
      if (this.urlsConfig) {
        loop1: for (const urlConfig of this.urlsConfig.urls) {
          if (location.includes(String(urlConfig.url))) {
            this.$root.$i18n.locale = urlConfig.loan_locale;
            for (const creditConfig in this.allCredits) {
              if (this.allCredits[creditConfig].loan_unique_name === urlConfig.loanConfig) {
                this.selectedLoanType.target_url = this.allCredits[creditConfig].target_url;
                this.selectedLoanType.i18n = this.allCredits[creditConfig].i18n;
                this.selectedLoanType.loan_unique_name = this.allCredits[creditConfig]
                  .loan_unique_name;
                this.selectedLoanType.credit = this.allCredits[creditConfig].sub_credits[0];
                if (this.allCredits[creditConfig].sub_credits.length > 1) {
                  this.subCredits = this.allCredits[creditConfig].sub_credits;
                } else {
                  this.subCredits = null;
                }
                foundConfig = true;
                break loop1;
              }
            }
          }
        }
      }
      if (!foundConfig) {
        this.$root.$i18n.locale = 'fr';
        if (this.allCredits) {
          this.selectedLoanType.target_url = this.allCredits[0].target_url;
          this.selectedLoanType.i18n = this.allCredits[0].i18n;
          this.selectedLoanType.loan_unique_name = this.allCredits[0].loan_unique_name;
          this.selectedLoanType.credit = this.allCredits[0].sub_credits[0];
          if (this.allCredits[0].sub_credits.length > 1) {
            this.subCredits = this.allCredits[0].sub_credits;
          } else {
            this.subCredits = null;
          }
        }
      }
    },
    findSource(location) {
      if (location.includes('facebook')) {
        this.source = 'FACEBOOK';
      } else if (location.includes('adwords')) {
        this.source = 'GOOGLE';
      } else if (location.includes('zanox')) {
        this.source = 'AWIN';
      } else if (location.includes('cm_mmc')) {
        this.source = 'ACTON';
      } else {
        this.source = 'ORGANIC';
      }
    },
    redirectIfDifferentCredit() {
      if (process.env.NODE_ENV === 'production') {
        const location = String(window.location.href);

        // if (location != this.selectedLoanType.target_url) {
        //   const urlToRedirect =
        //     `https://telecredit.be${this.selectedLoanType.target_url}`;
        //   window.location.replace(urlToRedirect);
        // }
      }

    },
    getSimulationForAllRanges() {
      const rangesSimulations = [];
      const firstLoop = true;

      for (const range of this.selectedLoanType.credit.ranges) {
        if (parseInt(range.range_min) <= this.loanAmount && parseInt(range.range_max) >=
          this.loanAmount) {
          for (const rangeDuration of range.range_durations) {
            for (const duration of rangeDuration.durations) {
              const monthlyPayments = this.monthlyCalculation(parseInt(duration), parseFloat(
                rangeDuration.range_rate));

              rangesSimulations.push({
                duration: parseInt(duration),
                monthlyPayments,
              });
            }
          }
        }
      }

      return rangesSimulations;
    },
    getMonthlyRepayment(creditType) {
      return this.monthlyCalculation(this.selectedRangeDuration, this
        .selectedRangeRate);
    },
    monthlyCalculation(duration, rangeRate) {
      const txInteretMensuel = (rangeRate / 100) / 12;
      return (this.loanAmount * txInteretMensuel) / (1 - Math.pow((1 + txInteretMensuel), -
        duration));
    },
    getSelectedRangeRate() {
      let selectedRangeRate = null;
      for (const rangeRate of this.selectedLoanType.credit.ranges) {
        if (this.loanAmount >= parseFloat(rangeRate.range_min) && this.loanAmount <=
          parseFloat(rangeRate.range_max)) {
          for (const rangeDuration of rangeRate.range_durations) {
            let lastDuration = rangeDuration.durations [0];
            for (const duration of rangeDuration.durations) {
              if (parseInt(duration) == this.selectedRangeDuration) {
                selectedRangeRate = rangeDuration.range_rate;
                break;
              }
              if(lastDuration < this.selectedRangeDuration && duration > this.selectedRangeDuration) {
                selectedRangeRate = rangeDuration.range_rate;
                break;
              }
            }
          }
        }
      }

      return selectedRangeRate;
    },
    getRangeMin() {
      for (const range of this.selectedLoanType.credit.ranges) {
        if (
          range.range_min - 1 <= this.loanAmount &&
          range.range_max >= this.loanAmount
        ) {
          return range.range_durations[0].durations[0];
        }
      }
    },
    getRangeMax() {
      for (const range of this.selectedLoanType.credit.ranges) {
        if (
          range.range_min <= this.loanAmount &&
          range.range_max >= this.loanAmount
        ) {
          const lastRangeDuration = range.range_durations.length - 1;

          return parseFloat(range.range_durations[lastRangeDuration].durations[range
            .range_durations[
              lastRangeDuration].durations.length - 1]);
        }
      }
    },
    changeToMaximumDuration() {
      this.selectedRangeDuration = this.getRangeMax();
    },
    isLoanJustBeforeRange() {
      for (const range of this.selectedLoanType.credit.ranges) {
        if (this.loanAmount + 1 == range.range_min) {
          this.loanAmount++;
        }
      }
    },
    isLoanInAmountRange() {
      if (this.loanAmount > this.amountMax) {
        this.loanAmount = this.amountMax;
      }
      if (this.loanAmount < this.amountMin) {
        this.loanAmount = this.amountMin;
      }
    },
    isLoanInDurationRange() {
      if (this.selectedRangeDuration > this.rangeMax) {
        this.selectedRangeDuration = this.rangeMax;
      }
      if (this.selectedRangeDuration < this.rangeMin) {
        this.selectedRangeDuration = this.rangeMin;
      }
    },
    incrementAmount() { this.loanAmount += this.amountIncrements; },
    decrementAmount() { this.loanAmount -= this.amountIncrements; },
    incrementDuration() {
      this.selectedRangeDuration += parseInt(this.selectedLoanType.credit.incrementationDuration);
    },
    decrementDuration() {
      this.selectedRangeDuration -= parseInt(this.selectedLoanType.credit.incrementationDuration);
    },
    isChoosenRange(duration) {
      if (parseInt(duration) === this.selectedRangeDuration) {
        return true;
      }
      return false;
    },
    isChoosenCredit(credit) {
      if (credit === this.selectedLoanType.loan_unique_name) {
        return true;
      }
      return false;
    },
    isChoosenCreditHaveSubCredits() {
      if (this.subCredits) {
        return true;
      }
      return false;
    },
    setSelectedLoanType(credit) {
      this.selectedLoanType.target_url = credit.target_url;
      this.selectedLoanType.i18n = credit.i18n;
      this.selectedLoanType.loan_unique_name = credit.loan_unique_name;
      this.selectedLoanType.credit = credit.sub_credits[0];
      this.loanAmount = this.selectedLoanType.credit.amount_default;
      if (credit.sub_credits.length > 1) {
        this.subCredits = credit.sub_credits;
      } else {
        this.subCredits = null;
      }
    },
    scrollTo(id) {
      var element = this.$refs[id];
      var top = element.offsetTop;
      window.scrollTo({
        top: top - 50,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
};